@font-face {
	font-family: 'Toon Cats';
	src: url('/public/Toon\ Cats.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  }
  
  * {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Toon Cats', 'Secular One', sans-serif;
}

body::-webkit-scrollbar {
	width: 8px;               /* width of the entire scrollbar */
	}
	
	body::-webkit-scrollbar-track {
	background: rgb(0, 0, 0);        /* color of the tracking area */
	}
	
	body::-webkit-scrollbar-thumb {
	background-color: #ffbb00;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 3px solid rgb(0, 0, 0);  /* creates padding around scroll thumb */
	}

